import React, { useEffect, useRef, useState } from 'react'
import { debounce } from "lodash"

function ReadMoreText({ 
    children, 
    text, 
    type, 
    hoveredRow, 
    hoveredTxt, 
    clickedRow, 
    parentEl, 
    checkCollapsible, 
    isText, 
    postClamped, 
    hasLink
}) {

    const containerRef = useRef(null)
    const moreBtnRef = useRef(null)

    const [textClamped, setTextClamped] = useState(null)
    const [textCollapsed, setTextCollapsed] = useState(null)
    const [showClamped, setShowClamped] = useState(null)
    const [clamped, setClamped] = useState(clickedRow ? false : true)
    const [showButton, setShowButton] = useState(true)

    useEffect(() => {
        if (isText) {
            const clampIndex = text?.indexOf('<!--more-->')
            if (clampIndex !== -1) {
                setShowClamped(true)
                const txtClamped = text?.substring(0, clampIndex)
                const txtCollapsed = text?.substring(clampIndex)
                setTextClamped(txtClamped)
                setTextCollapsed(txtCollapsed)
                // console.log('txtClamped', txtClamped)
                // console.log('txtCollapsed', txtCollapsed)
            }
        }

        const hasClamping = el => {
            const {clientHeight, scrollHeight} = el
            return clientHeight !== scrollHeight
        }

        const checkButtonAvailability = () => {
            if (containerRef.current) {
                // Save current state to reapply later if necessary.
                const hadClampClass = containerRef.current.classList.contains('clamp')
                // Make sure that CSS clamping is applied if aplicable.
                if (!hadClampClass) containerRef.current.classList.add('clamp')
                // Check for clamping and show or hide button accordingly.
                setShowButton(hasClamping(containerRef.current))
                // Sync clamping with local state.
                if (!hadClampClass) containerRef.current.classList.remove('clamp')
            }
        }

        const debouncedCheck = debounce(checkButtonAvailability, 50)

        checkButtonAvailability()
        window.addEventListener('resize', debouncedCheck)

        return () => window.removeEventListener('resize', debouncedCheck)
    }, [text])

    useEffect(() => {
        setClamped(type === 'news' ? postClamped : true)
    }, [postClamped])

    return (
        <div className={`${clamped ? 'isCollapsible' : ''}`} >
            {type === 'project' && <div 
                ref={containerRef}
                className={`text-black text-sm lg:text-medium leading-tight relative pr-4 xl:pr-8 ${clamped ? '' : ''}`}//line-clamp-2 lg:line-clamp-3
                dangerouslySetInnerHTML={{__html: isText && showClamped ? textClamped : text}}
                onClick={ () => setClamped(!clamped) }
            />}

            {isText && showClamped && !clamped && 
                <div 
                    className={`relative text-sm lg:text-medium leading-tight text-black ${type === 'news' ? 'px-0' : 'pr-4 xl:pr-8'}`} 
                    dangerouslySetInnerHTML={{__html: textCollapsed}} 
                />}

            {type === 'news' && hasLink && children}

            {!hasLink && !clamped && children}

            {isText && showClamped && 
                <div className='flex w-full mt-2.5'>
                    <button 
                        ref={moreBtnRef}
                        type='button'
                        className={`flex items-center font-folioLight leading-none z-30 focus:outline-none focus:ring-0 focus:ring-inset-0 text-black ${clamped ? 'rotate-45' : ''}`}
                        onClick={() => setClamped(!clamped)}
                    >
                        <svg className='w-3 h-3' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
                            {clamped ? <path strokeLinecap="square" strokeLinejoin="square" strokeWidth={`${hoveredTxt === parentEl ? '4' : '3'}`} d="M 0.339 23.661 L 23.661 0.339 M 0.339 0.339 L 23.661 23.661" /> : <path strokeLinecap="square" strokeLinejoin="square" strokeWidth="3" d="M 0 12 L 24 12"/>}
                        </svg>
                    </button>
                </div>}

            {!textClamped && showButton && 
                <div className='relative flex w-full'>
                    <button 
                        ref={moreBtnRef}
                        type='button'
                        className={`flex items-center font-folioLight text-black leading-none z-30 focus:outline-none focus:ring-0 focus:ring-inset-0 ${clamped ? 'rotate-45' : ''} my-2`}
                        onClick={() => setClamped(!clamped)}
                    >
                        <svg className='w-3 h-3' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
                            {clamped ? <path strokeLinecap="square" strokeLinejoin="square" strokeWidth={`${hoveredRow === parentEl ? '4' : '3'}`} d="M 0.339 23.661 L 23.661 0.339 M 0.339 0.339 L 23.661 23.661" /> : <path strokeLinecap="square" strokeLinejoin="square" strokeWidth="3" d="M 0 12 L 24 12"/>}
                        </svg>
                    </button>
                </div>}
        </div>
    )
}

export default ReadMoreText