import React, { useState, createContext, useEffect } from "react"

const themes = {
    light: {
        color: "black",
        background: "transparent",
    },
    dark: {
        color: "white",
        background: "black",
    }
}

const projectViews = [
    { value: 'selection' , name: 'Auswahl'},
    { value: 'grid' , name: 'Raster'},
    { value: 'list' , name: 'Liste'},
]

const initialState = {
    isLoading: true,
    showLogo: true,
    dark: false,
    theme: themes.light,
    hideLogoHorizontal: false,
    showIntro: true,
    introEnd: false,
    contentWidth: null,
    menuOpen: false,
    projectsView: null, //projectViews[0].value,
    filter: null, 
    searchTerm: '',
    isSearch: false,
    setLoading: () => {},
    setShowLogo: () => {},
    setDark: () => {},
    setHideLogoHorizontal: () => {},
    setShowIntro: () => {},
    setIntroEnd: () => {},
    setMenuOpen: () => {},
    setWidth: () => {},
    setProjectsView: () => {},
    setFilter: () => {},
    setSearchTerm: () => {},
    setIsSearch: () => {},
}

export const ThemeContext = createContext(initialState)

export const ThemeContextProvider = ({ children }) => {

    const [dark, setDark] = useState(false)
    const [showLogo, setShowLogo] = useState(false)
    const [hideLogoHorizontal, setHideLogoHorizontal] = useState(false)
    const [showIntro, setShowIntro] = useState(false)
    const [introEnd, setIntroEnd] = useState(false)
    const [contentWidth, setContentWidth] = useState(null)
    const [projectsView, setProjectsView] = useState(null) //projectViews[0].value
    const [categories, setCategories] = useState(null)
    const [isSearch, setIsSearch] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [menuOpen, setMenuOpen] = useState(false)
    const [isLoading, setLoading] = useState(true) 

    useEffect(() => {
        document.documentElement.style.backgroundColor = dark ? themes.dark.background : themes.light.background
    }, [dark])

    useEffect(() => {
        document.querySelectorAll('.logo-letter, .logo-initial, .logo-dot, .logo-dot-w').forEach(el => el.removeAttribute('style'))
    }, [introEnd])

    useEffect(() => {
        menuOpen ? document.documentElement.classList.add('overflow-hidden') : document.documentElement.classList.remove('overflow-hidden')
        menuOpen && setShowLogo(true)
        menuOpen && setHideLogoHorizontal(false)
    }, [menuOpen, projectsView])
    
    // useEffect(() => {
    //     console.log('projectsView', projectsView)
    // }, [projectsView])
    
    // useEffect(() => {
    //     console.log('Logo', showLogo)
    // }, [showLogo])

    return (
        <ThemeContext.Provider value={{ dark, setDark, showLogo, setShowLogo, hideLogoHorizontal, setHideLogoHorizontal, showIntro, setShowIntro, introEnd, setIntroEnd, menuOpen, setMenuOpen, projectViews, projectsView, setProjectsView, categories, setCategories, searchTerm, setSearchTerm, isSearch, setIsSearch, contentWidth, isLoading, setLoading, setContentWidth }}>
            {children}
        </ThemeContext.Provider>
    )
}