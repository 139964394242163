import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Accordion, AccordionHeader, AccordionBody } from '@material-tailwind/react'
import TabbedContent from '../TabbedContent/TabbedContent'
import Gallery from '../Gallery/Gallery'
import Image from '../Image/Image'
import { format } from 'date-fns'

function Icon({id, open, collapsibleHover}) {
  return (
    // <span className='block'>{collapsibleOpen ? '-' : '+'}</span>
    // <span className={`${id === open ? '' : null} `}>{id === open ? '-' : '+'}</span>
    // <svg className={`h-6 w-6 ${id === open ? '' : null} `} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
    //   <path strokeLinecap="butt" strokeLinejoin="round" strokeWidth=".75" d="M 0.339 23.661 L 23.661 0.339 M 0.339 0.339 L 23.661 23.661" />
    // </svg>
    <>
      {id === open ? 
        <svg className={`size-3`} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
          <path strokeLinecap="butt" strokeLinejoin="round" strokeWidth="3" d='M 0 16 L 32 16'/>
        </svg>
        :
        <svg className={`size-3 origin-center rotate-45 translate-y-1 m-0`} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
          <path strokeLinecap="butt" strokeLinejoin="round" strokeWidth="3" d="M 0.339 23.661 L 23.661 0.339 M 0.339 0.339 L 23.661 23.661" />
        </svg>
      }
    </>
  )
}

const CollapsibleContent = ({children, collapsibleHeader, collapsibleBody, pageContent, pageTitle, jobLocation, jobDate, type, elKey, isOpen}) => {
  
  const [collapsibleOpen, setCollapsibleOpen] = useState(isOpen ? isOpen : null)
  const [collapsibleHover, setCollapsibleHover] = useState(false)

  const handleCollapsibleOpen = value => setCollapsibleOpen(collapsibleOpen === value ? null : value)

  // useEffect(() => {
    // console.log(isOpen)
    // console.log('collapsibleOpen', collapsibleOpen)
    // handleCollapsibleOpen(collapsibleOpen)
  // }, [collapsibleOpen, isOpen])

  return (
    <Accordion 
      className='py-2 border-b border-white hover:border-white'
      open={collapsibleOpen === elKey} 
      icon={<Icon id={elKey} open={collapsibleOpen} collapsibleHover={collapsibleHover} />}
      onMouseEnter={() => setCollapsibleHover(true)}
      onMouseLeave={() => setCollapsibleHover(false)}
    >
           
      <div className='relative flex justify-between lg:py-2 collapsibleHeader'>
        <AccordionHeader
          className={`${collapsibleOpen ? 'open' : null} border-0 text-medium text-left !normal-case`}
          onClick={() => handleCollapsibleOpen(elKey)}
        >
          {jobLocation && jobDate ? <>
            <div className={`flex flex-col max-lg:flex-col-reverse lg:flex-row lg:justify-between w-full lg:w-11/12 gap-x-8 py-2`}>
              <span className='!mb-0 lg:w-28'>{jobLocation}</span>
              <span className='!mb-0 lg:grow'>{collapsibleHeader}</span>
              <span className='!mb-0 '>{format(new Date(jobDate), "MM/yyyy")}</span>
            </div>
          </> : <>
          {collapsibleHeader}
          {pageTitle}
          </>
          }
        </AccordionHeader>
      </div>
      
      <AccordionBody className={`relative pt-2 md:p-0 flex flex-wrap collapsibleBody`} id={elKey}>
        {pageContent && type === 'tabs' ? <TabbedContent data={pageTitle === 'Archiv' ? 'projects' : 'awards'} content={pageContent}/> : <CollapsiblePageContent content={pageContent} />}
        <AccordionContent content={collapsibleBody} />
        {children}
      </AccordionBody>

    </Accordion>
  )
}

export default CollapsibleContent


const CollapsiblePageContent = ({content}) => {
  // useEffect(() => {
  //   console.log(content)
  // }, [])

  return (
    <div className='w-full my-2'>
      {content?.map(el => {
        return (
          <div key={el.id} className='mb-6'>
            <Link to={`/page/jobs/${el.slug}`} preventScrollReset={false} state={{jobId: el.id, jobSlug: el.slug}} className='block !no-underline !mb-0 text-sm md:text-base font-folioMedium'>
              {el.title.rendered}
            </Link>

            <p className='!mb-0 text-sm'>{el.acf.job_buro}</p>
            
            {el.acf.job_pdf && 
              <p className='text-sm md:text-base'>
                <a target='_blank' rel='noreferrer' href={el.acf.job_pdf} className='text-sm underline underline-offset-2'>Jobbeschreibung</a>
              </p>}
          </div>
        )
      })}
    </div>
  )
}

const AccordionContent = ({ content}) => {
  return (
    <div className='relative flex flex-wrap gap-x-8'>
      {content?.map((el, i) => (
        <div key={i} className={`relative ${el.content_width === '12' ? 'w-full' : `md:w-${el.content_width}-12`} mb-8`}>
          
          {el.content_elements !== false && el.content_elements?.map((el, i) => {
            return (
              <div key={i}>
                {(el.acf_fc_layout === 'content_element_txt' && el.txt) && <div className='my-3' dangerouslySetInnerHTML={{__html: el.txt}}></div>}
                {(el.acf_fc_layout === 'content_element_images') && el.images?.length > 1 ? <Gallery images={el.images} arrows={false} autoplay={true} ratio={parseFloat(el.images[0]?.height / el.images[0]?.width).toFixed(2)} /> : <Image img={el.images ? el.images[0] : null} isModal={false} />}
              </div>
            )
          })}
          
        </div>
      ))}
    </div>
  )
}