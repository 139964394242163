import React from 'react'
import Image from '../Image/Image'

const ImageGrid = ({images}) => {
  return (
    <div className='relative grid w-full grid-cols-1 lg:grid-cols-2 gap-y-8 lg:gap-y-[15vh]'>
      {images?.map((img, i) =>
        <div className={`flex ${i % 2 === 0 ? '' : 'lg:justify-end'}`}>
          <div className={`w-full ${img.width /img.height >= 1 ? 'lg:w-5/6' : 'lg:w-1/2'}`}>
            <Image img={img} isModal={false} preview=""/>
          </div>
        </div>)}
    </div>
  )
}

export default ImageGrid