import React, { useState, useRef, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { ThemeContext } from '../../ThemeContext'
import { useFetch } from '../../useFetch'
import CollapsibleContent from '../CollapsibleContent/CollapsibleContent'
import PageContent from '../PageContent/PageContent'

const Page = () => {
  const { slug, postname } = useParams()
  
  const page = useFetch(`https://cms.pws.eu/wp-json/wp/v2/pages/?slug=${slug}`)
  const jobs = useFetch('https://cms.pws.eu/wp-json/wp/v2/jobs')
  const awards = useFetch('https://cms.pws.eu/wp-json/pws/v1/awards')
  const news = useFetch('https://cms.pws.eu/wp-json/pws/v1/news')
  const team = useFetch('https://cms.pws.eu/wp-json/pws/v1/team')
  const teamTerms = useFetch('https://cms.pws.eu/wp-json/pws/v1/team_positions')
  
  // const collapsibleOpen = postname //state?.jobId ||
  const contentRefs = useRef([])
  const refPage = useRef(null)

  // const { isLoading, setLoading } = useContext(ThemeContext)
  
  const [pageTitle, setPageTitle] = useState(null)
  const [pageContent, setPageContent] = useState(null)
  const [newsPosts, setNewsPosts] = useState(null)
  const [jobPosts, setJobPosts] = useState(null)
  const [currentJob, setCurrentJob] = useState(null)
  const [awardPosts, setAwardPosts] = useState(null)
  const [teamPositions, setTeamPositions] = useState(null)
  const [teamPosts, setTeamPosts] = useState(null)
  // const [awardsPage, setAwardsPage] = useState(null)
  // const [jobsPage, setJobsPage] = useState(null)

  // useEffect(() => {
  //   fetch(`https://cms.pws.eu/wp-json/wp/v2/pages/?slug=${slug}`) 
  //     .then(res => res.json())
  //     .then(data => {
  //       console.log(data)
  //       setPageTitle(data?.[0]?.title?.rendered)
  //       setPageContent(data?.[0]?.acf?.buro_content)
  //     })
    
  //   fetch(`https://cms.pws.eu/wp-json/pws/v1/news`) 
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setNewsPosts(data)
  //     })
    
  //   fetch(`https://cms.pws.eu/wp-json/wp/v2/jobs`) 
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setJobPosts(data)
  //     })

  //   fetch(`https://cms.pws.eu/wp-json/pws/v1/team_positions`) 
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setTeamPositions(data)
  //     })

  //   fetch(`https://cms.pws.eu/wp-json/pws/v1/team`) 
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setTeamPosts(data)
  //     })
  // }, [slug])
  
  useEffect(() => {    
    if (!page.loading) {
      setPageTitle(page?.data?.[0]?.title?.rendered)
      setPageContent(page?.data?.[0]?.acf?.buro_content)
    }

    if (!teamTerms.loading) 
      setTeamPositions(teamTerms?.data)
    
    if (!team.loading) 
      setTeamPosts(team?.data)

    if (!news.loading) 
      setNewsPosts(news?.data)

    if (!jobs.loading) {
      setJobPosts(jobs?.data)
      postname && setCurrentJob(jobs?.data?.filter(el => el.slug === postname))
    }

    if (!awards.loading)
      setAwardPosts(awards?.data)

    // if (!page.loading && !teamTerms.loading && !awards.loading && !team.loading && !news.loading && !jobs.loading) 
    //   setLoading(false)
    
  }, [slug, jobs, awards, news, teamTerms, team, pageContent, postname]) 
  
  return (
    <div ref={refPage} className={`relative min-h-screen text-sm text-left max-lg:mt-8 ${slug !== 'buero' ? 'pt-[2.5vw]' : ''} page`}> 
      <div className='flex flex-wrap w-full gap-y-10 lg:gap-y-20 mb-[33vh]'>
        <PageContent
          title={pageTitle} 
          slug={slug} 
          contentItems={pageContent} 
          teamPositions={teamPositions} 
          teamPosts={teamPosts} 
          jobPosts={jobPosts} 
          currentJob={currentJob}
          awardPosts={awardPosts} 
          newsPosts={newsPosts}
        />

        {(slug === 'jobs' || slug === 'karriere') && jobPosts &&  
          <div className='relative w-full mt-10 border-t border-white xl:w-2/3'>
            {jobPosts?.map((job, i) => (
              <CollapsibleContent
                key={i} 
                collapsibleHeader={job.title.rendered} 
                jobLocation={job.acf.job_buro} 
                jobDate={job.date} 
                type={'page'} 
                elKey={job.slug} 
                // isOpen={collapsibleOpen}
              >
                {job.acf.job_description && 
                  <div 
                    className={`relative w-full lg:flex lg:flex-col`} 
                    dangerouslySetInnerHTML={{__html: job.acf.job_description}}
                  />}
                
                {job.acf.job_pdf && 
                  <p className='text-sm lg:text-base'>
                    <a target='_blank' rel='noreferrer' href={job.acf.job_pdf} className='text-sm underline underline-offset-2'>
                      Jobbeschreibung
                    </a>
                  </p>}
              </CollapsibleContent>
            ))}
          </div>}
      </div>
    </div>
  )
}

export default Page