import React, { useEffect, useState } from 'react'
import { Accordion, AccordionHeader, AccordionBody } from '@material-tailwind/react'

function Icon({id, open, collapsibleHover}) {
  return (
    <>
      {id === open ? 
        <svg className={`size-3 -translate-y-1`} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
          <path strokeLinecap="butt" strokeLinejoin="round" strokeWidth="3" d='M 0 16 L 32 16'/>
        </svg>
        :
        <svg className={`size-3 origin-center rotate-45 m-0`} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
          <path strokeLinecap="butt" strokeLinejoin="round" strokeWidth="3" d="M 0.339 23.661 L 23.661 0.339 M 0.339 0.339 L 23.661 23.661" />
        </svg>
      }
    </>
  )
}

const CollapsibleText = ({children, collapsibleHeader, collapsibleBody, pageContent, pageTitle, type, elKey, isOpen}) => {
  
  const [collapsibleOpen, setCollapsibleOpen] = useState(isOpen ? isOpen : null)

  const handleCollapsibleOpen = value => setCollapsibleOpen(collapsibleOpen === value ? null : value)

  return (
    <Accordion 
      className='py-1'
      open={collapsibleOpen === elKey} 
      icon={<Icon id={elKey} open={collapsibleOpen} />}
    >
           
      <div className='relative flex gap-x-2 md:py-2 collapsibleHeader'>
        <AccordionHeader
          className={`${collapsibleOpen ? 'open' : null} border-0 text-md text-left !normal-case flex-wrap justify-start gap-x-2`}
          onClick={() => handleCollapsibleOpen(elKey)}
        >
          {collapsibleHeader}
          {pageTitle}
        </AccordionHeader>
      </div>
      
      <AccordionBody className={`relative pt-2 md:p-0 flex flex-wrap collapsibleBody`} id={elKey}>
        {children}
      </AccordionBody>

    </Accordion>
  )
}

export default CollapsibleText
