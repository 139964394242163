import React, { useRef, useState } from 'react'
import Modal from '../Modal/Modal'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useWindowSize } from '@uidotdev/usehooks'
import 'react-lazy-load-image-component/src/effects/blur.css'

const Image = ({
  img, 
  altTxtSubtitle, 
  preview, 
  galleryImg, 
  isModal, 
  isPost, 
  postClamped, 
  setPostClamped,
  firstItem,
  view,
  noBlur = false
}) => {
    
  gsap.registerPlugin(ScrollTrigger)

  const size = useWindowSize()

  const refImg = useRef(null)

  const [imgLoaded, setImgLoaded] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const landscape = img?.width / img?.height >= 1 ? true : false

  return (
    img &&
      <>
        <figure ref={refImg}
          className={`relative ${firstItem ? 
            '!mt-0 h-full'
            : 
            `${galleryImg ? 
              'w-auto mx-auto h-full' 
              : 
              `w-full mb-4 ${landscape ? 
                `${view === 'selection' ? 
                  'lg:aspect-16/10 !mb-0' 
                  : 
                  'lg:aspect-4/3 !mb-0'
                }` 
                : 
                `${view === 'selection' ? 
                  'lg:aspect-2/3 w-2/3 lg:w-full !mb-0' 
                  : 
                  'lg:aspect-10/16 !mb-0'
                }`
              } 
            `}
          `} overflow-hidden z-0`}
          
          onClick={() => isModal && size.width >= 1024 ? setShowModal(true) : (isPost && postClamped ? setPostClamped(!postClamped) : null)}
        >
          <picture>
            {preview === 'home' || preview === 'projects' || isPost ? 
              <>
                <source
                  media='(max-width: 1439px)'
                  srcSet={img.sizes?.[preview === 'home' ? 'resp-medium' : 'resp-small']}
                />
                <source
                  media='(min-width: 1440px)'
                  srcSet={img.sizes?.[preview === 'home' ? 'resp-large' : 'resp-medium']}
                />
              </>
              : 
              <>
                <source
                  media='(min-width: 1920px)'
                  srcSet={`${img.sizes?.['resp-full']}`}
                />
                <source 
                  media='(min-width: 1280px)'
                  srcSet={`${img.sizes?.['resp-xlarge']}`}
                />
                <source 
                  media='(min-width: 769px)'
                  srcSet={`${img.sizes?.['resp-large']}`}
                />
                <source 
                  media='(max-width: 768px)'
                  srcSet={`${img.sizes?.['resp-small']}`}
                />
              </>}
              
            <LazyLoadImage
              alt={img.alt ? img.alt : altTxtSubtitle}
              onLoad={() => setImgLoaded(true)}
              src={img.sizes?.['resp-small']}
              className={`relative z-0 ${firstItem ? 'group-hover:grayscale' : ''} ${galleryImg ? `w-full h-full ${landscape ? 'object-cover object-center' : `${isPost ? 'object-left' : 'lg:mx-auto lg:w-auto object-center'} object-contain`}` : `w-full`} h-full lg:hover:opacity-100 bg-white ${preview !== '' ? 'lg:scale-[1] lg:hover:scale-[1.05]' : ''} ${isModal ? 'cursor-pwsCross' : ''} ${!noBlur ? (imgLoaded ? 'blur-none' : 'blur-sm') : ''} transition-all duration-1000 ease-[cubic-bezier(.1,.6,.4,1)]`}
            />
          </picture>
        </figure>

        {isModal && <Modal showModal={showModal} setShowModal={setShowModal} img={img} landscape={landscape} />}
      </>
  )
}

export default Image