import { useContext, useEffect, useRef } from "react"
import { useOnClickOutside } from 'usehooks-ts'
import { ThemeContext } from "../../ThemeContext"

export default function Searchbar({ toggleSearch, searchProjects }) {

    const { searchTerm, setSearchTerm, isSearch, setIsSearch } = useContext(ThemeContext)
    
    const refSearchInput = useRef()
    const refSearchBar = useRef()

    const handleKeyDown = event => event.key === 'Enter' && searchProjects()
    
    useOnClickOutside(refSearchBar, () => toggleSearch(false))

    // useEffect(() => {
    //   console.log(searchTerm)  
    // //   setFilter(searchTerm)
    // }, [searchTerm])

    return (
        <div ref={refSearchBar} className={`pb-2 lg:py-0 relative block cursor-pointer ${isSearch ? 'grow w-full' : 'a2xl:w-3/5'} m-auto bg-transparent`}>
            <div className='flex flex-nowrap'>
                <button 
                    type="button" 
                    className="inline-flex items-center justify-center pr-2 text-black hover:text-black focus:outline-none focus-within:shadow-xs focus:ring-0 focus:ring-inset-0"
                    onClick={() => {
                        setSearchTerm('')
                        toggleSearch(false)
                    }}
                >
                    <svg className="size-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="butt" strokeLinejoin="round" strokeWidth=".75" d="M 0.339 23.661 L 23.661 0.339 M 0.339 0.339 L 23.661 23.661" />
                    </svg>
                </button>

                <input
                    ref={refSearchInput}
                    type='text'
                    id='search'
                    className='px-1 text-sm bg-transparent border-b border-black md:w-3/4 xl:w-1/2 grow input focus:outline-0' 
                    placeholder='Suchbegriff'
                    name='search'
                    autoFocus
                    onChange={event => setSearchTerm(event.target.value)}
                    onKeyDown={handleKeyDown}
                    value={searchTerm}
                />

                <button
                    type="button"
                    className="inline-flex items-center justify-center px-1 text-sm text-black lg:whitespace-nowrap shrink font-folioLight hover:font-folioMedium focus:outline-none focus:ring-0 focus:ring-inset-0"
                    onClick={searchProjects}
                >
                    Suchen
                </button>
            </div>
        </div>
    )
}