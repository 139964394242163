import React, { useEffect, useState } from 'react'

import { LazyLoadImage } from 'react-lazy-load-image-component'

const NewsContent = ({content}) => {

  const [fullContent, setFullContent] = useState(false)
  
  useEffect(() => {
  }, [])

  return (
    <div className={`relative overflow-hidden h-[30rem] flex-none ${fullContent ? 'md:basis-[45rem] overflow-auto' : 'md:basis-[280px]'} bg-[#676767]`}>
      <div className={`relative w-full h-full p-2 ${fullContent ? 'overflow-auto' : ''}`}>
        <div className='relative mb-5 cursor-pointer' onClick={() => setFullContent(!fullContent)}>
          {content.img && <LazyLoadImage
            alt={content.img?.[0]?.alt ? content.img[0].alt : content.title}
            src={content.img?.[0]?.sizes?.['resp-small']}
            className={`relative mb-3 ${content.img?.[0]?.width > content.img?.[0]?.height ? 'w-full md:max-w-[264px]' : 'md:w-3/4 md:max-w-[10rem] mx-auto'}`}
          />}
          <p className='!mb-1 text-sm'>{content.postdate}</p>
        </div>

        <p className='!mb-1 text-sm font-folioMedium uppercase'>{content.title}</p>
        
        <div className={`${fullContent ? '' : 'line-clamp-4'}`} dangerouslySetInnerHTML={{__html: content.txt}} />
        
        <div className='flex items-center justify-center w-full'>
          <button 
            type='button'
            className={`font-folioLight leading-none mt-3 py-2 focus:outline-none focus:ring-0 focus:ring-inset-0 text-white ${fullContent ? 'rotate-45' : ''}`}
            onClick={() => setFullContent(!fullContent)}
          >
            <svg className='-rotate-45 size-3' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
              {fullContent ? <path strokeLinecap="square" strokeLinejoin="square" strokeWidth="3" d="M 0 12 L 24 12"/> : <path strokeLinecap="square" strokeLinejoin="square" strokeWidth='4' d="M 0.339 23.661 L 23.661 0.339 M 0.339 0.339 L 23.661 23.661" />}
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}

export default NewsContent