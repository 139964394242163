import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import NewsContent from '../NewsContent/NewsContent'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const TabbedContent = ({data, content, tabs}) => {

  const [openTab, setOpenTab] = useState(tabs ? tabs[0].year : content?.[0]?.year)

  return (
    <div className='relative w-full tabs'>
      <div className='relative flex flex-col w-full my-2'>
        
        <div className='relative flex mb-6 overflow-auto gap-x-2'>
          {data === 'news' ?
            tabs?.map(tab => 
              <p key={tab.id}>
                <span
                  onClick={() => setOpenTab(tab.year)}
                  className={`${tab.year === openTab ? 'underline underline-offset-4 decoration-1' : ''} block pr-2 text-white text-medium cursor-pointer`}
                >
                  {tab.year}
                </span>
              </p>)
            :
            content?.map(tab => (
              <p key={tab.id}>
                <span
                  onClick={() => setOpenTab(tab.year)}
                  className={`${tab.year === openTab ? 'underline underline-offset-4 decoration-1' : ''} block pr-2 text-white text-medium cursor-pointer`}
                >
                  {tab.year}
                </span>
              </p>)
            )}
        </div>

        <div className='relative w-full mt-3 overflow-hidden'>
          {data === 'news' ?
            tabs?.map(tab => {
              const yearContent = content?.filter(el => el.year === tab.year)
              return (
                <div 
                  key={tab.year}
                  id={`year-${tab.year}`} 
                  className={`relative ${tab.year === openTab ? 'flex' : 'hidden'} w-full max-w-none flex-nowrap overflow-auto gap-x-4`}
                >
                  {yearContent?.map(el =>
                    <Fragment key={el.id}>
                      <NewsContent content={el} />
                    </Fragment>)}
                </div>
              )
            })
            :
            content?.map(tab => (
              <div
                id={tab.id}
                key={tab.id}
                className={`relative ${tab.year === openTab ? 'flex' : 'hidden'} w-full max-w-none flex-nowrap overflow-auto gap-x-4`}
              >
                {data === 'projects' && tab.projects?.map(project => (
                  <div key={project.id} className={`relative overflow-hidden h-[30rem] flex-none md:basis-[280px] bg-[#676767]`}>
                    <div className={`relative w-full h-full p-2`}>
                      <div className='relative mb-5 cursor-pointer'>
                        {project.img && <LazyLoadImage
                          alt={project.img?.[0]?.alt ? project.img[0].alt : project.name}
                          src={project.img?.[0]?.sizes?.['resp-small']}
                          className={`relative mb-3 ${project.img?.[0]?.width > project.img?.[0]?.height ? 'w-full md:max-w-[264px]' : 'md:w-3/4 md:max-w-[10rem] mx-auto'}`}
                        />}
                        <p className='!mb-1 text-sm'>{project.name}</p>
                      </div>
                    </div>
                  </div>
                ))}
                
                {/* <p key={project.id} className='!mb-1 text-sm md:text-base'>
                  {project.project_link ?
                    <>{project.title} {project.location && <>, {project.location}</>}</>
                  :
                    <Link to={`/projekt/${project.slug}`} preventScrollReset={false} className='block my-2 underline md:my-0 underline-offset-4 decoration-1'>
                      {project.title} {project.location && <span>, {project.location}</span>}
                    </Link> 
                  }
                </p> */}

                {data === 'awards' && tab.awards?.map((award, i) => (
                  <div key={i} className='mb-6'>
                    {award.projects?.map(project => (
                      <div key={project.id} className={`relative overflow-hidden h-[30rem] flex-none md:w-[280px] bg-[#676767]`}>
                        <div className={`relative w-full h-full p-2`}>
                          <div className='relative mb-5 cursor-pointer'>
                            {project.img && <LazyLoadImage
                              alt={project.img?.alt ? project.img.alt : project.name}
                              src={project.img?.sizes?.['resp-small']}
                              className={`relative mb-3 ${project.img?.width > project.img?.height ? 'w-full md:max-w-[264px]' : 'md:w-3/4 md:max-w-[10rem] mx-auto'}`}
                            />}
                            <p className='!mb-1 text-sm font-folioMedium uppercase'>{award.name}</p>
                            <p className='!mb-1 text-sm'>{project.name}</p>
                            {project.location && <p className='!mb-1 text-sm'>{project.location}</p>}
                            <Link to={`/projekt/${project.slug}`} preventScrollReset={false} className='block mt-4 underline text-medium underline-offset-4 decoration-1'>
                              zum Projekt
                            </Link>
                          </div>
                        </div>
                      </div>
                      // <p key={project.id} className='!mb-1 text-sm my-2 md:my-0'>
                      //   <Link to={`/projekt/${project.slug}`} preventScrollReset={false} className='block underline underline-offset-4 decoration-1'>
                      //     {project.name} {project.location && <span>, {project.location}</span>}
                      //   </Link>
                      // </p>
                    ))}
                  </div>
                ))} 
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default TabbedContent


// {data === 'news' && 
//                 <div className='mb-6'>
//                   <p className='!mb-1 text-sm font-folioMedium'>{tab.title}</p>
//                   {/* {JSON.stringify(tab.txt)} */}
//                   <div className='my-3' dangerouslySetInnerHTML={{__html: tab.txt}} />
//                 </div>}