import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeContext"

function LogoS() {
  
  const { dark, showLogo } = useContext(ThemeContext)

  return (
    <svg
        className={`absolute bottom-10 visible lg:hidden ${showLogo ? '!opacity-100' : 'opacity-0'} ${dark ? 'text-white' : 'text-black'}`}
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMinYMid"
        fill="currentColor"
        viewBox="378 424 988 424"
    >
      <path
        d="M696.386 543.314c-1.693-18.755-2.836-32.022-11.299-48.946a107.985 107.985 0 00-28.226-35.817c-33.301-28.455-69.988-34.722-113.445-34.722-64.363 0-104.435 18.297-125.341 36.961-28.772 25.07-31.608 60.338-31.608 74.017a96.202 96.202 0 005.627 34.125c5.67 18.801 18.662 41.168 59.467 57.5 21.455 7.959 45.149 13.722 91.488 22.186 28.225 5.123 71.728 12.533 94.875 33.028a65.76 65.76 0 0118.617 44.966c-.547 36.964-29.916 65.414-63.812 76.255a161.698 161.698 0 01-51.922 6.818c-15.826 0-55.303.547-88.058-30.742-26.53-25.022-28.817-52.331-30.51-66.559l-43.457-.592c5.076 32.019 8.463 45.515 15.231 59.741a134.516 134.516 0 0026.533 35.316 128.28 128.28 0 0033.896 23.329c36.138 17.063 79.048 17.655 90.896 17.655a279.612 279.612 0 0058.689-8.003c23.147-7.365 27.447-9.15 48.58-23.329a203.753 203.753 0 0025.938-24.475 112.629 112.629 0 0022.597-67.701c0-21.639-7.365-76.257-66.603-98.488-18.664-6.816-81.881-19.898-102.193-23.878-28.82-5.123-76.211-11.939-93.135-40.394a51.702 51.702 0 01-7.365-27.903 60.716 60.716 0 0115.826-42.679c23.696-25.619 60.383-29.596 94.828-29.596 50.32 0 77.355 15.919 92.039 32.019a73.171 73.171 0 0118.481 49.908z"
        className={`${showLogo ? '!opacity-100' : 'opacity-0'} transition-opacity duration-300 logo-letter logo-name logo-initial`} 
      />

      <path
        d="M713.265 791.248H756.722V834.4300000000001H713.265z"
        className={`${showLogo ? '!opacity-100' : 'opacity-0'} transition-opacity duration-300 logo-letter logo-dot logo-initial logo-name`}
      />
    </svg>
  )
}

export default LogoS
