import { useContext, useEffect, useState } from "react"
import { ThemeContext } from "./ThemeContext"

export const useFetch = url => {

    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    // const [loading, setLoading] = useState(null)
    const { loading, setLoading } = useContext(ThemeContext)

    useEffect(() => {
        // setLoading(true)
        
        fetch(url)
            .then(response => response.json())
            .then(setData)
            .catch(setError)
            .finally(() => {
                setLoading(false)
            })
    }, [url])

    return { data, error, loading }
}