
import { useEffect } from 'react'
import { useIntersectionObserver } from 'usehooks-ts'

const ContentTxt = ({ children, setIsInViewport, anchor, name, layout }) => {

  const { isIntersecting, ref } = useIntersectionObserver({
    // root: document.querySelector("#root"),
    threshold: 0.1,
  })

  useEffect(() => {
    console.log(`Content ${name}`, {
      isIntersecting,
    }) 
    isIntersecting && setIsInViewport(name)
  }, [isIntersecting]);

  return (
    <section
      ref={ref}
      id={anchor !== null ? anchor : null}
      className={`relative w-full ${layout} ${anchor !== null ? 'pt-30 lg:pt-40' : ''}`}
    >
      {children}
    </section>
  )
}

export default ContentTxt 