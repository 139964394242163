import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { ThemeContext } from '../../ThemeContext'
import Project from '../Project/Project'
import Image from '../Image/Image'
import Post from '../Post/Post'
import Gallery from '../Gallery/Gallery'
import FilterProjectView from '../FilterProjectView/FilterProjectView'

function Projects() {

  const { isLoading, setLoading, setShowLogo, showIntro, projectsView, setProjectsView, categories, setCategories, isSearch, setIsSearch, setSearchTerm, searchTerm } = useContext(ThemeContext)
 
  const refProjects = useRef(null)
  
  const location = useLocation()
  const { pathname } = useLocation()

  // const [searchParams] = useSearchParams({})
  // const [searchParamVal, setSearchParamVal] = useState(null)
  const [allProjects, setAllProjects] = useState(null)
  const [projectSelection, setProjectSelection] = useState(null)
  const [projectsWettbewerbe, setProjectsWettbewerbe] = useState(null)
  const [projects, setProjects] = useState(null)
  const [projectList, setProjectList] = useState(null)
  const [intro, setIntro] = useState(null)
  const [customIntro, setCustomIntro] = useState(null)
  const [selectionRows, setSelectionRows] = useState(0)
  const [selectionRowsArr, setSelectionRowsArr] = useState(null)
  const [projectsLandscape, setProjectsLandscape] = useState(null)
  const [projectsPortrait, setProjectsPortrait] = useState(null)
  const [searchResult, setSearchResult] = useState(null)
  const [filter, setFilter] = useState(null)

  async function getProjects() {
    const introContent = await fetch('https://cms.pws.eu/wp-json/pws/v1/intro')
    if (!introContent.ok) return
    const introResults = await introContent.json()
    introResults?.length !== 0 && setIntro(introResults)

    const selectionProjects = await fetch('https://cms.pws.eu/wp-json/pws/v1/projectSelection')
    if (!selectionProjects.ok) return
    const selectionResults = await selectionProjects.json()
    setProjectSelection(selectionResults)

    const allProjects = await fetch('https://cms.pws.eu/wp-json/pws/v1/projects_dev')
    if (!allProjects.ok) return
    const projectResults = await allProjects.json()
    setAllProjects(projectResults)
    setProjectsWettbewerbe(projectResults.filter(el => el.categories?.length === 1 && el.categories[0] === 'WETTBEWERBE'))

    const projectCategories = await fetch('https://cms.pws.eu/wp-json/wp/v2/projectcategory?per_page=100')
    if (!projectCategories.ok) return
    const categories = await projectCategories.json()
    setCategories(categories)
  }

  async function searchProjects() {
    if (searchTerm !== '') {
      const searchProjects = await fetch(`https://cms.pws.eu/wp-json/pws/v1/search?s=${searchTerm}`)
      
      setProjectsView('grid')
      if (searchProjects.ok) {
        const searchResults = await searchProjects.json()
        // setSearchResult(searchResults?.filter(el => el.title.includes('Copy')))
        setSearchResult(searchResults)
      } else {
        setSearchResult(null)
      }
      // setLoading(false)
    }
  }
  
  useEffect(() => {
    setFilter(null)
    if (projectsView !== 'grid') {
      setIsSearch(false)
      setSearchTerm('')
      setSearchResult(null)
    }
    setProjectList(projectsView === 'selection' ? projectSelection : projects)
    setLoading(false)
  }, [projectsView, projects, projectSelection])

  useEffect(() => {
    pathname === '/' && projectsView === 'selection' && setShowLogo(true)
  }, [projectsView, pathname])

  useEffect(() => {
    if (!isSearch) {
      setSearchTerm('')
      setSearchResult(null)
    }
  }, [isSearch])

  useEffect(() => {
    // console.log('filter', filter)
    setIsSearch(false)
    setSearchTerm('')
    setSearchResult(null)
    filter === 'WETTBEWERBE' ? setProjectList(projectsWettbewerbe) : setProjectList(filter === null ? projects : projects?.filter(el => el.categories?.includes(filter)))
  }, [filter])

  useEffect(() => {
    // console.log('allProjects', allProjects)
    // console.log('projectsWettbewerbe', projectsWettbewerbe)
    setProjects(allProjects?.filter(project => !projectsWettbewerbe?.some(wettbewerb => wettbewerb.id === project.id)))
  }, [allProjects, projectsWettbewerbe])
  
  useEffect(() => {
    // console.log('projectSelection', projectSelection)
    // console.log('intro', intro)
    setCustomIntro(intro !== null ? true : false)
    setSelectionRows(projectSelection?.length % 2 === 0 ? projectSelection?.length/2 : (projectSelection?.length + 1)/2)
    setProjectsLandscape(intro !== null ? projectSelection?.filter((project, _) => project.posttype === 'projects' || project.posttype === 'selection' ? project.img?.width / project.img?.height >= 1 : project.img?.[0]?.width / project.img?.[0]?.height >= 1) : projectSelection?.slice(1).filter((project, _) => project.posttype === 'projects' || project.posttype === 'selection' ? project.img?.width / project.img?.height >= 1 : project.img?.[0]?.width / project.img?.[0]?.height >= 1))
    setProjectsPortrait(intro !== null ?  projectSelection?.filter((project, _) => project.posttype === 'projects' || project.posttype === 'selection' ? project.img?.width / project.img?.height < 1 : project.img?.[0]?.width / project.img?.[0]?.height < 1) : projectSelection?.slice(1).filter((project, _) => project.posttype === 'projects' || project.posttype === 'selection' ? project.img?.width / project.img?.height < 1 : project.img?.[0]?.width / project.img?.[0]?.height < 1))
  }, [projectSelection, intro])

  useEffect(() => {
    // console.log('selectionRows', selectionRows)
    // console.log('projectsLandscape', projectsLandscape)
    // console.log('projectsPortrait', projectsPortrait)
    selectionRows > 0 && setSelectionRowsArr([...Array(selectionRows).keys()].map(i => {
      const row = {
        landscape: projectsLandscape[i],
        portrait: projectsPortrait[i]
      }
      return row
    }))
  }, [selectionRows, projectsLandscape, projectsPortrait])
  
  // useEffect(() => {
  //   console.log('projectList', projectList)
  // }, [projectList])
  
  useEffect(() => {
    console.log(pathname)
    setProjectsView(pathname === '/' ? 'selection' : 'grid')
  }, [pathname])

  useEffect(() => {
    getProjects()
  }, [])
  
  return (
    <div ref={refProjects} id="projects" className={`relative ${projectsView === 'selection' ? 'lg:grid lg:grid-cols-2 max-lg:gap-y-8 lg:gap-x-10 lg:justify-between' : `lg:grid ${projectsView === 'grid' ? 'flex flex-col lg:grid-cols-4 lg:gap-x-6 gap-y-8 lg:gap-y-12' : 'lg:grid-cols-12'}`}`}>
      
      {projectsView !== 'selection' && <>
        <FilterProjectView
          categories={categories}
          filter={filter}
          setFilter={setFilter} 
          searchProjects={searchProjects}
        />
      </>}
        
      {(projectsView === 'selection') ? 
        <>
          {projectSelection?.length >= 1 && pathname === '/' && (location.showIntro || showIntro) &&
            <div id="intro" className='relative w-full lg:col-span-2 lg:h-full max-lg:mt-8'>
              {customIntro ?
                <Link to={`${intro?.intro_link}`} target={intro?.intro_link_target ? intro?.intro_link_target : '_self'} className='relative grid grid-cols-1 overflow-hidden lg:h-full lg:w-full group'>
                  <div className={`relative h-full col-span-1 text-sm lg:!text-lg`}>
                    {intro?.intro_img != null && 
                      (intro?.intro_img?.length > 1 ?
                        <Gallery 
                          images={intro.intro_img} 
                          fullscreen={true} 
                          type="fade"
                          arrows={false} 
                          autoplay={4000} 
                          autoPlayActive={true} 
                          pagination={false} 
                          isPost={false}
                          isProject={false} 
                        />
                        :
                        <div className='w-full aspect-16/10 lg:h-[calc(100vh-2.5vw)] pt-[2.5vw]'>
                          <Image
                            img={intro?.intro_img[0]}
                            altTxtSubtitle="Peter W. Schmidt Architekten"
                            preview="projects"
                            view={'grid'}
                            firstItem
                            galleryImg
                          />
                        </div>
                        )}
                    <div className={`absolute w-full h-full inset-x-0 inset-y-[2.5vw] flex items-center justify-center z-10 transition-opacity duration-300 lg:group-hover:opacity-100 lg:opacity-0 bg-white/50`}> 
                      {intro?.intro_txt && <div className='' dangerouslySetInnerHTML={{__html: intro.intro_txt}} /> }
                    </div>
                  </div>
                </Link>  
                :
                <Project
                  content={projectSelection?.[0]}
                  width={projectSelection?.[0].content_el_width}
                  view={projectsView}
                  firstItem={true}
                />
              }
            </div>}
          
          <div className='relative w-full lg:col-span-2'>
            <FilterProjectView
              categories={categories}
              filter={filter}
              setFilter={setFilter}
              searchProjects={searchProjects}
            />

            <div className='relative flex flex-wrap justify-between gap-y-8 lg:gap-y-[15vh] lg:gap-x-[12.5%] max-lg:mt-8'>
              {projectSelection?.map((el, _) => {
                return (
                  el.posttype === 'projects' || el.posttype === 'selection' ? 
                    <Project 
                      index={el.id}
                      type={el.posttype}
                      content={el} 
                      width={`${el.imgCentered ? 'w-full flex justify-center' : (el.img?.width / el.img?.height >= 1 ? 'lg:w-5/12' : 'lg:w-1/4')}`} //${el.img?.width / el.img?.height >= 1 ? 'lg:w-5/12' : 'lg:w-1/4'}
                      view={projectsView} 
                      centered={el.imgCentered ? (el.img?.width / el.img?.height >= 1 ? 'lg:w-5/12' : 'lg:w-1/4') : ''}
                    />
                    :
                    <Post 
                      index={el.id}
                      content={el} 
                      width={`lg:w-5/12`} 
                    />
                )
                }
              )}
            </div>

            {/* <div className='relative grid grid-cols-1 lg:col-span-2 gap-y-8 lg:gap-y-[15vh] max-lg:mt-8'>
              {selectionRowsArr?.map((row, i) =>
                <div key={i} className={`relative flex gap-y-8 lg:gap-0 lg:justify-between flex-col ${i % 2 === 1 ? 'lg:flex-row-reverse' : 'lg:flex-row'}`}>
                  {row?.landscape && (row.landscape?.posttype === 'projects' || row.landscape?.posttype === 'selection' ? 
                    <Project 
                      index={i}
                      type={row.landscape?.posttype}
                      content={row.landscape} 
                      width={`lg:w-5/12 row-${i}`} 
                      view={projectsView} 
                    />
                    :
                    <Post 
                      index={i}
                      content={row.landscape} 
                      width={`lg:w-5/12 row-${i}`} 
                    />)}
                  
                  {row?.portrait && (row.portrait?.posttype === 'projects' || row.portrait?.posttype === 'selection' ?
                    <Project 
                      index={i}
                      type={row.portrait?.posttype}
                      content={row.portrait} 
                      width={`lg:w-1/3 row-${i}`} 
                      view={projectsView} 
                    />
                    : 
                    <Post 
                      index={i}
                      content={row.portrait}  
                      width={`lg:w-5/12 row-${i}`} 
                    />)}
                </div>
              )}  
            </div> */}
          </div>
        </>
        
        : 
        
        <>
          {searchResult === null && projectList?.map((project, i) => 
            <div key={i} className={`relative w-full ${projectsView === 'list' ? 'lg:col-span-12' : ''} cursor-pointer project`}>
              <Project 
                content={project} 
                width={project.content_el_width} 
                view={projectsView} 
                firstItem={false}
              />
            </div>)}

          {(isSearch && searchResult != null) && <p className='lg:col-span-4'>Für den Suchbegriff <strong>{searchTerm}</strong> wurden {searchResult?.length >= 1 ? 'folgende' : 'keine'} Projekte gefunden.</p>}

          {searchResult?.map((project, i) => 
            <div key={i} className={`relative w-full ${projectsView === 'list' ? 'lg:col-span-12' : ''} cursor-pointer project`}>
              <Project 
                content={project} 
                width={project.content_el_width} 
                view={projectsView} 
                firstItem={false}
              />
            </div>)}
        </>
      }
    </div>
  )
}

export default Projects

// const imgAlign = (i % 2 ? (location.pathname === '/' ? 'flex flex-wrap justify-start items-start' : 'flex flex-wrap justify-end items-start') : (location.pathname === '/' ? 'flex flex-wrap justify-end items-start' : 'flex flex-wrap justify-start items-start'))
{/* // ${projectsView === 'selection' ? (`${imgAlign} col-span-1 md:mb-24`) */}