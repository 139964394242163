import React, { useEffect, useRef, useState } from 'react'
import ReadMoreText from '../ReadMoreText/ReadMoreText'
// import { useWindowSize } from '@uidotdev/usehooks'

const ProjectData = ({projectData}) => {

  // const size = useWindowSize()
  
  const rowRefs = useRef([])
  const collapsibleRefs = useRef([])

  const [isCollapsableRow, setIsCollapsableRow] = useState(false)
  const [hoveredRow, setHoveredRow] = useState(null)
  const [clickedRow, setClickedRow] = useState(false)

  // const checkCollapsible = val => {
  //   // setIsCollapsableRow(true)
  //   console.log('is clamp', val)
  // }
  
  return (
    <div className={`relative md:m-auto`}>
      {projectData?.map((el, i) => {
        return (
          <div
            key={i}
            ref={el => rowRefs.current[i] = el}
            className={`relative md:flex gap-8 border-b ${isCollapsableRow ? 'md:hover:border-black' : 'border-grey'} py-2 project-data`}
            onMouseEnter={() => isCollapsableRow && setHoveredRow(rowRefs.current[i])}
            onMouseLeave={() => isCollapsableRow && setHoveredRow(null)}
            onClick={() => setClickedRow(!clickedRow)}
          >
            <div className='text-sm uppercase md:basis-4/12' dangerouslySetInnerHTML={{__html: el.tablet_col_left_headline}} />

            <div className='relative md:basis-8/12 project-data-row' ref={el => collapsibleRefs.current[i] = el}>
              <div 
                  className={`relative text-sm lg:text-medium leading-tight text-black`} 
                  dangerouslySetInnerHTML={{__html: el.table_col_right}} 
              />
              {/* <ReadMoreText 
                text={el.table_col_right} 
                type={'project'}
                hoveredRow={hoveredRow} 
                clickedRow={clickedRow} 
                parentEl={rowRefs.current[i]} 
                // checkCollapsible={checkCollapsible}
              /> */}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ProjectData