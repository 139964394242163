import React, { useContext, useEffect, useRef, useState } from "react"
import { ThemeContext } from "../../ThemeContext"

function LogoPeter() {

  const { dark, showLogo } = useContext(ThemeContext)
  
  const refDot = useRef(null)

  const [dotWidth, setDotWidth] = useState(0)

  useEffect(() => {
    setDotWidth(refDot?.current?.getBoundingClientRect().width - 5)
    // setFilterOffset((refFilterBtn.current?.parentElement.offsetWidth - refFilterBtn.current?.offsetWidth) / 2)
  }, [refDot])
  
  // useEffect(() => {
  //   console.log(dotWidth)
  // }, [dotWidth])

  return (
    <svg
        className={`invisible lg:visible lg:block ${dark ? 'text-white' : 'text-black'}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        preserveAspectRatio="xMinYMin meet"
        viewBox="0 0 1213.92 195.03"
        // style={{ transform: `translateX(${dotWidth}px)` }}
    >
      <path
        d="M135.37 53.8c0 23.76-16.11 35.23-37.14 35.23H21.3V19.7h76.93c21 0 37.14 10.39 37.14 34.14m19.51.58v-.59-.53c0-34.68-22.39-53.25-55.15-53.25H0v194.72h21.3V108.7h79.13c32.37-.27 54.45-19.88 54.45-54.3"
        className={`${showLogo ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300 logo-initial`}
      />

      <path
        ref={refDot}
        d="M150.33 173.83H171.61V194.97000000000003H150.33z"
        className={`${showLogo ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300 logo-dot`}
        
      />

      <path
        d="M192.55 0.01L192.55 194.97 315.97 194.97 315.97 175.31 213.85 175.31 213.85 106.51 302.32 106.51 302.32 86.85 213.85 86.85 213.85 19.67 315.97 19.67 315.97 0.01 192.55 0.01z"
        className={`opacity-0 transition-opacity duration-300 logo-letter logo-name`}
      />

      <path
        d="M343.36 0.02L343.36 18.41 405.21 18.41 405.21 195.03 427.22 195.03 427.22 18.69 489.35 18.69 489.35 0.02 343.36 0.02z"
        className={`opacity-0 transition-opacity duration-300 logo-letter logo-name`} 
      />

      <path
        d="M516.73 0.01L516.73 194.97 640.15 194.97 640.15 175.31 538.03 175.31 538.03 106.51 626.5 106.51 626.5 86.85 538.03 86.85 538.03 19.67 640.15 19.67 640.15 0.01 516.73 0.01z"
        className={`opacity-0 transition-opacity duration-300 logo-letter logo-name`} 
      />

      <path
        d="M786.04 53.79c0 23.76-16.11 35.23-37.13 35.23h-60.08V18.57h60.08c21 0 37.13 11.47 37.13 35.22m21.3-.54C807.34 18.57 784.95 0 752.18 0h-84.63v195h21.29v-87.4h62.53c24 0 32 7.09 32 29.48v25.33c0 24.42 5 32.56 5 32.56h21.3s-5-8-5-32.56V138.7c0-28.4-4.91-35-28.94-42.05v-.55c18.56-6.82 31.67-22.39 31.67-42.87"
        className={`opacity-0 transition-opacity duration-300 logo-letter logo-name`}
      />
    </svg>
  )
}

export default LogoPeter