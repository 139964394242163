import React, { useContext, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ThemeContext } from '../../ThemeContext'

function Menubar({children, menuData, contactData}) {

  const { dark, introEnd, menuOpen, setMenuOpen } = useContext(ThemeContext)
  
  const refNav = useRef(null)
  const refMenu = useRef(null)

  return (
    <>
      <div className={`fixed z-50 flex cursor-pointer top-3 right-2 lg:top-[2.5vw] lg:right-[2.5vw] ${introEnd ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}>
        <div 
          className='relative z-40 flex flex-col items-center w-8 h-[28px] space-y-3 lg:space-y-4'
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <span className={`block w-[24px] lg:w-[38px] h-[3px] lg:h-[5px] ${dark ? 'bg-white' : 'bg-black'} ${menuOpen ? 'translate-y-[8px] lg:translate-y-[10px] rotate-45' : ''} transition-transform origin-center duration-150`} aria-hidden='true'></span>
          <span className={`block w-[24px] lg:w-[38px] h-[3px] lg:h-[5px] ${dark ? 'bg-white' : 'bg-black'} ${menuOpen ? '-translate-y-[7px] lg:-translate-y-[10px] -rotate-45' : ''} transition-transform origin-center duration-150`} aria-hidden='true'></span>
        </div>
      </div>
      
      <div 
        ref={refNav}
        className={`${dark ? 'text-white' : 'text-black'} flex flex-col justify-center fixed inset-0 h-full w-full ${menuOpen ? `opacity-100 z-40 ${dark ? 'bg-black' : 'bg-white'}` : 'opacity-0 z-0'} transition-opacity duration-300`} 
      >

        <div 
          ref={refMenu}
          className={`relative flex flex-wrap justify-center w-full lg:w-2/5 2xl:w-5/12 lg:mx-auto h-auto z-20`} 
        >
          <Menu menuData={menuData} />            
        </div>
        
        <div className='absolute z-10 hidden lg:block right-20 bottom-6'>
          <div className='flex gap-x-8 xl:flex-col menu-contact' dangerouslySetInnerHTML={{__html: contactData}} />
        </div>
        
        {children}
      </div>
    </>
  )
}

export default Menubar

function Menu({ menuData }) {

  const {pathname} = useLocation()
  const navigate = useNavigate()
  const { setShowLogo, setMenuOpen, setProjectsView } = useContext(ThemeContext)

  const handleMenuClick = path => {
    if (path === pathname ) {
      setMenuOpen(false)
      if (pathname === '/') {
        setShowLogo(true)
        setProjectsView('selection')
      }
    } else {
      navigate(path)
    }
  }
  
  return (
    <div className={`relative flex flex-col w-full gap-y-3 px-3 lg:px-0 whitespace-nowrap overflow-hidden uppercase text-base menu`}>
      {menuData?.map((item, i) => {
        const title = item.linkTitle
        const href = item.href

        return (
          <span 
            key={i} 
            className={`relative text-center block font-folioMedium lg:hover:underline lg:hover:decoration-1 lg:hover:underline-offset-4 cursor-pointer`} 
            onClick={() => handleMenuClick(href)}
          >
            {title}
          </span>
        )            
      })}
    </div>
  )
}